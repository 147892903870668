.lia-li {
  display: flex;
  text-align: left;
  list-style: none;
  flex: 0 1 50%;

  &.lia-align-right {
    justify-content: flex-end;
    text-align: right;
    margin-left: auto;

    .lia-link {
      grid-template:
        'head icon'
        'subheading .';
      grid-template-rows: var(--lia-icon-size-24) auto;
    }
  }
}

.lia-link {
  display: grid;
  grid-template:
    'icon head'
    '. subheading';
  grid-template-rows: var(--lia-icon-size-24) auto;
  font-weight: var(--lia-bs-font-weight-normal);
  column-gap: 4px;
}

.lia-icon {
  grid-area: icon;
}

.lia-heading {
  grid-area: head;
  color: var(--lia-bs-gray-700);
}

.lia-subheading {
  grid-area: subheading;
  color: var(--lia-bs-body-color);
  font-size: var(--lia-bs-font-size-sm);
}
